<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <title>Adam Lahey Webpage</title>
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Space+Mono&display=swap" rel="stylesheet">
  <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet">

</head>

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-172966057-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-172966057-1');
</script>

<body class="body">
  <div class="header-background">
    <div>
        <nav class="navbar navbar-expand-md navbar-dark bg-dark">
            <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a routerLink="/home" id="nav-link" class="nav-link animated fadeInUp" style="max-width: 940px;">Home</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about" id="nav-link" class="nav-link animated fadeInUp" style="max-width: 940px;">About</a>
                    </li>
                    <li class="nav-item">
                        <a href="/assets/Lahey_Resume.pdf" target="_blank" id="nav-link" class="nav-link animated fadeInUp" style="max-width: 940px;">Resume</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact" id="nav-link" class="nav-link animated fadeInUp" style="max-width: 940px;">Contact</a>
                    </li>
                </ul>
            </div>
            <div class="mx-auto order-0">
                <a class="navbar-brand mx-auto site-title animated fadeInLeft" style="color: rgb(255, 255, 255);">Adam Lahey</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
                <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a href="skype:adamjl_4?chat" 
                            id="nav-link" class="nav-link"><i class="icon-skype"></i></a>
                        </li>
                    <li class="nav-item">
                        <a href="https://github.com/adamjl217" 
                        id="nav-link" class="nav-link" target="_blank"><i class="icon-github"></i></a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.linkedin.com/in/adamlahey/" 
                        id="nav-link" class="nav-link" target="_blank"><i class="icon-linkedin"></i></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
  </div>

  <router-outlet></router-outlet>

</body>