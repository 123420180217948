<div class="body-container" opacity="0.3">
    <div class="card">
        <div class="card-header">
            <h3>Contact Info for Adam Lahey</h3>
        </div>
        <div class="card-body">
            <h6><b>Email</b></h6>
            <h6><a href="mailto:adamjl217@gmail.com">adamjl217@gmail.com</a></h6>
            <br />
            <br />
            <h6><b>Social Media</b></h6>      
            <h6><a href="skype:adamjl_4?chat">Skype</a></h6>
            <h6><a href="https://github.com/adamjl217">GitHub</a></h6>
            <h6><a href="https://linkedin.com/in/adamlahey">LinkedIn</a></h6>
            
            <br />
            <br />
            <h6><b>Phone</b></h6>
            <h6><a href="tel:14193778203">+1 (419) 377-8203</a></h6>
        </div>
    </div>
</div>