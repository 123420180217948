<body>
    <div class="header-background">
        <div class="headline-container">
            <h1 id="headline" class="heading animated zoomIn slower">Lead Software Developer in Test.</h1>
            <h5 id="subline" class="subhead typewriter">Reliable, High-Performance Software Through Innovative Testing</h5>
        </div>
    </div>
    <div class="body-container" opacity="0.3">
        <div class="card">
            <div class="card-header">
                <h4>Adam Lahey</h4>
                <h6>
                    I'm an experienced Senior Software Development Engineer in Test (SDET) with a strong focus on creating and refining robust testing frameworks, optimizing development processes, and leveraging the latest methodologies to ensure high-quality software delivery. My expertise spans a wide range of tools and technologies, including Selenium, Cucumber, AWS, and various programming languages like C#, Python, and Java. I am passionate about delivering reliable and maintainable software solutions through innovative testing strategies.                    .</h6>
                <h6>
                    In addition to my expertise in software testing and automation, I have a strong background in physics and astronomy, including the operation and management of telescopes and advanced equipment like video dome projectors. As the President of the Society of Physics Students, I led initiatives that fostered academic growth and collaboration. My academic journey includes minors in Computer Science, Mathematics, and Astronomy, and I've authored research papers on photometric calibration and nanocrystal emission. I’ve also tutored students in subjects ranging from C++ and Astronomy to Calculus and Statistics, sharing my passion for both theoretical and applied sciences.
                </h6>
                <h6>
                    I'm looking to grow my professional career by working with innovative technologies at great
                    companies.
                </h6>
            </div>
        </div>
    </div>
</body>